import { StaticImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react'

const randomFromInterval = (min: number, max: number)=> {
        return Math.floor(Math.random() * (max - min + 1) + min);
      }

export default function RandomNumberForm() {

    const onLoadRannumber = randomFromInterval(200000, 300000);
    const [rndNum, setRndNum] = useState<number | null>(onLoadRannumber);

    const frameImage = 'https://static-perf1.zupee.com/blog-images/uploads/2024/12/Frame-356.webp';

    

    useEffect(()=>{
      const timeout = setInterval(()=> {
        setRndNum(randomFromInterval(200000, 300000));
    }, 5000);
    return () => clearInterval(timeout);
    },[rndNum]);
    

  return (
    <div className='frame__wrapper__form'>
        <div className='frame__wrapper__form__inner'>
            <StaticImage alt="random number" src={frameImage} width={43}/> <span className='bold__text'>{rndNum} players </span><span className='normal__text'>currently playing</span>
        </div>
    </div>
  )
}
