import React from 'react';
import { Helmet } from 'react-helmet';

export default function FbPixel() {
	return (
		<Helmet 
      script={
        [{
          id : 'facebook-pixel-script-main',
          type: 'text/javascript', 
          innerHTML: `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '1271831939931284');` 
        }]
      }
    />
	);
};

export function FbPixelLs6() {
	return (
		<Helmet 
      script={
        [{
          id : 'facebook-pixel-script-main',
          type: 'text/javascript', 
          innerHTML: `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '1452817605402541');` 
        }]
      }
    />
	);
};

const FbPixelEvent_PageView = () => {
  console.log('Triggered: FB_pixel_Event-PageView');
  (window.fbq) && window.fbq('track', 'PageView');
}

const FbPixelEvent_Lead = () => {

  console.log('Triggered: FB_pixel_Event-Lead');
  //alert("lead");
  (window.fbq) && window.fbq('track', 'Lead');
}

export { FbPixelEvent_Lead, FbPixelEvent_PageView }